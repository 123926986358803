<template>
  <b-container fluid>
      <div class="iq-card p-2">
        <ul class="m-0 p-0 nav nav-tabs justify-content-start packageSelected">
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'package'}"  exact>Package/Service</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'addPackage'}">New Package</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'newservices'}">New Service</router-link>
          </li>
        </ul>
      </div>
    <keep-alive>
      <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                  :leave-active-class="`animated ${animated.exit}`">
        <router-view/>
      </transition>
    </keep-alive>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
export default {
  name: 'package',
  data () {
    return {
      animated: { enter: 'fadeInUp', exit: 'fadeOut' }
    }
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
.nav-link.router-link-active{
  border-bottom: 2px solid var(--iq-primary) !important;
  color: var(--iq-primary) !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;
}
</style>
